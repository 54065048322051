const HeaderTextButton = (() => {
    let headerTextButton = null;
    let textContent = null;
    let textContainer = null;
    let toggleButton = null;

    let self = {
        toggleContent: () => {
            if (!textContainer.classList.contains("expanded")) {
                textContainer.classList.add('expanded');
            } else {
                textContainer.classList.remove('expanded');
            }
        },
        init: (id) => {
            headerTextButton = document.getElementById(id);
            if (!headerTextButton.classList.contains("showmore"))
                return;
            textContent = headerTextButton.querySelector('.text-content');
            textContainer = headerTextButton.querySelector('.text-container');
            toggleButton = headerTextButton.querySelector(".toggle-btn")

            const lineHeight = parseFloat(getComputedStyle(textContent).lineHeight);
            const maxHeight = 7 * lineHeight;

            if (textContent.scrollHeight <= maxHeight) {
                toggleButton.classList.add("d-none");
            } else {
                toggleButton.classList.remove("d-none");
                toggleButton.addEventListener("click", self.toggleContent);
            }
        }
    }
    return self;
})();

window.HeaderTextButton = HeaderTextButton;